<template>
  <main-template>
    <secondary-pages-background />
    <section class="p-information p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Раскрытие информации
          </h1>
          <div class="divider" />
          <p class="mb-l ta--center">
            Аттестация рабочих мест
          </p>
          <p class="fs--small fw--light mb-l">
            С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей! Брелок CLICKTOID имеет уникальный ID номер, информацию для Нашедшего и телефон круглосуточной Службы поддержки.
          </p>
          <app-link class="mb-l" to="/">
            Перечень рекомендуемых мероприятий от 30.10.2019
          </app-link>
          <app-link class="mb-l" to="/">
            Сводная ведомость результатов проведения спецоценки условий труда
          </app-link>
          <app-link class="mb-l" to="/">
            Перечень рекомендуемых мероприятий от 17.01.2020
          </app-link>
          <app-link class="mb-l" to="/">
            Сводная ведомость результатов проведения спецоценки условий труда от 17.01.2020
          </app-link>
        </div>
      </div>
    </section>
  </main-template>
</template>

<script>
import MainTemplate from '@/layouts/MainTemplate'
import AppLink from '@/components/common/AppLink'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'

export default {
  name: 'Information',
  components: {
    MainTemplate,
    AppLink,
    SecondaryPagesBackground,
  }
}
</script>

<style lang="scss" scoped>
  .p-information {
    .wrapper {
      max-width: 640px;
    }
  }
</style>
